/*
| Developed by Dirupt
| Filename : HomeOurStory.tsx
| Author : Philippe DESPLATS (philippe@di-rupt.com)
*/

import React from 'react'
import Grid from '@mui/material/Unstable_Grid2'
import Container from '@mui/material/Container'
import Button from '@mui/material/Button'
import { m } from 'framer-motion'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import { MotionViewport, varFade } from '@/components/animate'
import { urlsConfig } from '@/config/urls.config'
import Iconify from '@/components/iconify'
import Image from '@/components/image'

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export default function HomeOurStory() {
	return (
		<Container
			component={MotionViewport}
			sx={{
				py: { xs: 10, md: 15 },
			}}
		>
			<Grid container alignItems="center" justifyContent="space-between" spacing={{ xs: 5, md: 0 }}>
				<Grid xs={12} md={6} display={{ md: 'initial', xs: 'none' }}>
					<m.div variants={varFade().inUp}>
						<Image disabledEffect alt="notre histoire" src="/assets/images/home/story.png" />
					</m.div>
				</Grid>

				<Grid xs={12} md={6}>
					<Stack
						sx={{
							textAlign: {
								xs: 'center',
								md: 'left',
							},
						}}
					>
						<m.div variants={varFade().inUp}>
							<Typography component="div" variant="overline" sx={{ color: 'text.disabled' }}>
								Une entreprise Française 🇫🇷
							</Typography>
						</m.div>

						<m.div variants={varFade().inUp}>
							<Typography variant="h2" sx={{ my: 3 }}>
								Notre histoire
							</Typography>
						</m.div>

						<m.div variants={varFade().inUp}>
							<Typography
								sx={{
									mb: 5,
									color: 'text.secondary',
								}}
							>
								L’histoire de SHPV débute en 2012. À l’époque, la micro-entreprise propose ses services
								en tant qu’hébergeur revendeur. En 2014, un tournant s’opère : l’entreprise grandit et
								change de statut pour devenir une SAS, avec à sa tête Guillaume Putier. Un premier
								investissement est réalisé afin de créer une infrastructure totalement indépendante. Le
								13 juillet 2017, SHPV allume son Backbone et ses premières fibres.
							</Typography>
						</m.div>

						<m.div variants={varFade().inUp}>
							<Button
								aria-label={'Découvrir nos services'}
								size="large"
								color="inherit"
								variant="outlined"
								href={urlsConfig.services.index}
								endIcon={<Iconify icon="eva:arrow-ios-forward-fill" width={18} sx={{ ml: -0.5 }} />}
							>
								Découvrir nos services
							</Button>
						</m.div>
					</Stack>
				</Grid>
			</Grid>
		</Container>
	)
}
