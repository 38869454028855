/*
| Developed by Dirupt
| Filename : HomeBenefits.tsx
| Author : Philippe DESPLATS (philippe@di-rupt.com)
*/

import React from 'react'
import { m } from 'framer-motion'
import { alpha, styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import { bgGradient, textGradient } from '@/theme/css'
import { MotionViewport, varFade } from '@/components/animate'
import { StyledAdvancedPolygon } from '@/components/divider/PolygonDivider'

/*
|--------------------------------------------------------------------------
| Styles
|--------------------------------------------------------------------------
*/
const StyledHero = styled(Box)(({ theme }) => ({
	...bgGradient({
		startColor: alpha(theme.palette.common.black, 0.8),
		endColor: alpha(theme.palette.common.black, 0.8),
		imgUrl: '/assets/background/home/benefits.jpg',
	}),
	backgroundPosition: 'top center',
	padding: theme.spacing(8, 0),
	overflow: 'hidden',
	position: 'relative',
}))

const StyledH2Gradient = styled(m.h2)(({ theme }) => ({
	...textGradient(
		`300deg, ${theme.palette.secondary.lighter} 0%, ${theme.palette.secondary.main} 25%, ${theme.palette.secondary.lighter} 50%, ${theme.palette.secondary.main} 75%, ${theme.palette.secondary.lighter} 100%`,
	),
	...theme.typography.h5,
	backgroundSize: '400%',
	textAlign: 'center',
}))

/*
|--------------------------------------------------------------------------
| Compoment
|--------------------------------------------------------------------------
*/
export default function HomeBenefits() {
	return (
		<StyledHero>
			<Container
				component={MotionViewport}
				sx={{
					py: { xs: 10, md: 15 },
				}}
			>
				<Box
					gap={{ xs: 3, lg: 10 }}
					display="grid"
					alignItems={'stretch'}
					gridTemplateColumns={{
						xs: 'repeat(1, 1fr)',
						md: 'repeat(3, 1fr)',
					}}
				>
					<m.div variants={varFade().inUp}>
						<Box>
							<StyledH2Gradient>Prestataire Français</StyledH2Gradient>
							<Typography sx={{ color: 'common.white', textAlign: 'center' }}>
								SHPV incarne l'excellence française, portant haut les valeurs nationales à travers son
								savoir-faire et son engagement.
							</Typography>
						</Box>
					</m.div>
					<m.div variants={varFade().inUp}>
						<Box>
							<StyledH2Gradient>Faible empreinte écologique</StyledH2Gradient>
							<Typography sx={{ color: 'common.white', textAlign: 'center' }}>
								Nous nous engageons pour un environnement plus propre, avec une technologie de
								refroidissement à faible consommation d'énergie.
							</Typography>
						</Box>
					</m.div>
					<m.div variants={varFade().inUp}>
						<Box>
							<StyledH2Gradient>Services adaptés à vos besoins</StyledH2Gradient>
							<Typography sx={{ color: 'common.white', textAlign: 'center' }}>
								Nos solutions sont spécifiquement élaborées pour répondre aux besoins uniques de chaque
								entreprise.
							</Typography>
						</Box>
					</m.div>
				</Box>
			</Container>

			<Box position={'absolute'} top={0} left={0} right={0} sx={{ transform: 'rotate(180deg)' }}>
				<StyledAdvancedPolygon anchor="left" opacity={0.48} sx={{ width: '100%' }} />
				<StyledAdvancedPolygon anchor="left" opacity={0.48} sx={{ width: '100%', height: 48, zIndex: 10 }} />
				<StyledAdvancedPolygon anchor="left" sx={{ width: '100%', zIndex: 11, height: 24 }} />
			</Box>

			<Box position={'absolute'} bottom={0} left={0} right={0}>
				<StyledAdvancedPolygon anchor="left" opacity={0.48} sx={{ width: '100%' }} />
				<StyledAdvancedPolygon anchor="left" opacity={0.48} sx={{ width: '100%', height: 48, zIndex: 10 }} />
				<StyledAdvancedPolygon anchor="left" sx={{ width: '100%', zIndex: 11, height: 24 }} />
			</Box>
		</StyledHero>
	)
}
