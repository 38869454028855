/*
| Developed by Dirupt
| Filename : HomeView.tsx
| Author : Philippe DESPLATS (philippe@di-rupt.com)
*/

'use client'

import Box from '@mui/material/Box'
import HomeHero from './sections/HomeHero'
import HomeServices from './sections/HomeServices'
import MainLayout from '@/layouts/main'
import HomeOurStory from '@/components/modules/home/sections/HomeOurStory'
import HomeBenefits from '@/components/modules/home/sections/HomeBenefits'
import HomeExpertise from '@/components/modules/home/sections/HomeExpertise'

/*
|--------------------------------------------------------------------------
| Home view
|--------------------------------------------------------------------------
*/
export default function HomeView() {
	return (
		<MainLayout>
			<HomeHero />

			<Box
				sx={{
					overflow: 'hidden',
					position: 'relative',
					bgcolor: 'background.default',
					pb: { xs: 8, md: 10 },
				}}
			>
				<HomeServices />

				<HomeOurStory />

				<HomeBenefits />

				<HomeExpertise />

				{/*<HomeCallToAction />*/}
			</Box>
		</MainLayout>
	)
}
