/*
| Developed by Dirupt
| Filename : HomeExpertise.tsx
| Author : Philippe DESPLATS (philippe@di-rupt.com)
*/

import React from 'react'
import Grid from '@mui/material/Unstable_Grid2'
import Container from '@mui/material/Container'
import Button from '@mui/material/Button'
import { m } from 'framer-motion'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import { MotionViewport, varFade } from '@/components/animate'
import { urlsConfig } from '@/config/urls.config'
import Iconify from '@/components/iconify'
import Image from '@/components/image'

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export default function HomeExpertise() {
	return (
		<Container
			component={MotionViewport}
			sx={{
				py: { xs: 10, md: 15 },
			}}
		>
			<Grid container alignItems="center" justifyContent="space-between" spacing={{ xs: 5, md: 0 }}>
				<Grid xs={12} md={6}>
					<Stack
						sx={{
							textAlign: {
								xs: 'center',
								md: 'left',
							},
						}}
					>
						<m.div variants={varFade().inUp}>
							<Typography component="div" variant="overline" sx={{ color: 'text.disabled' }}>
								10 ans d’expérience
							</Typography>
						</m.div>

						<m.div variants={varFade().inUp}>
							<Typography variant="h2" sx={{ my: 3 }}>
								Notre expertise
							</Typography>
						</m.div>

						<m.div variants={varFade().inUp}>
							<Typography
								sx={{
									mb: 5,
									color: 'text.secondary',
								}}
							>
								Avec plus de 10 ans d'expérience, nous sommes devenus un leader en matière
								d'infrastructure et d'infogérance informatique en France. Notre réactivité, notre
								méthodologie de travail et notre présence sur le territoire français font de SHPV le
								partenaire idéal pour accompagner votre entreprise dans son évolution. Notre expertise
								s'étend de l'hébergement web aux solutions sur mesure, en passant par la gestion de la
								sécurité informatique.
							</Typography>
						</m.div>

						<m.div variants={varFade().inUp}>
							<Button
								aria-label={'Demander un devis'}
								size="large"
								color="inherit"
								variant="outlined"
								href={urlsConfig.askQuote}
								endIcon={<Iconify icon="eva:arrow-ios-forward-fill" width={18} sx={{ ml: -0.5 }} />}
							>
								Demander un devis
							</Button>
						</m.div>
					</Stack>
				</Grid>

				<Grid xs={12} md={6} display={{ md: 'initial', xs: 'none' }}>
					<m.div variants={varFade().inUp}>
						<Image
							sx={{ float: 'right' }}
							disabledEffect
							alt="notre histoire"
							src="/assets/images/home/expertise.png"
						/>
					</m.div>
				</Grid>
			</Grid>
		</Container>
	)
}
