/*
| Developed by Dirupt
| Filename : HomeServices.tsx
| Author : Philippe DESPLATS (philippe@di-rupt.com)
*/

'use client'

import { m } from 'framer-motion'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import { alpha } from '@mui/material/styles'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import { varFade, MotionViewport } from '@/components/animate'
import { urlsConfig } from '@/config/urls.config'
import { RouterLink } from '@/routes/components'

/*
|--------------------------------------------------------------------------
| Constants
|--------------------------------------------------------------------------
*/
const CARDS = [
	{
		href: urlsConfig.services.infrastructure,
		icon: '/assets/icons/home/ic_make_brand.svg',
		title: 'Infrastructure',
		description:
			"Découvrez l'infrastructure de pointe de SHPV conçue pour répondre à vos besoins spécifiques en matière d'hébergement et d'infrastructure informatique.",
	},
	{
		href: urlsConfig.services.infogerance,
		icon: '/assets/icons/home/ic_design.svg',
		title: 'Infogérance',
		description:
			"Externalisez la gestion de votre système d'information avec SHPV. Nous proposons des solutions d'infogérance informatique sur mesure pour répondre aux besoins des TPE, PME, ETI et grandes entreprises.",
	},
	{
		href: urlsConfig.services.datacenter,
		icon: '/assets/icons/home/ic_development.svg',
		title: 'Datacenter',
		description:
			"Découvrez le cœur névralgique de l'hébergement informatique de SHPV - des datacenters ultra sécurisés. Une solution d'hébergement sur mesure pour les entreprises de toutes tailles.",
	},
]

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export default function HomeServices() {
	return (
		<Container
			component={MotionViewport}
			sx={{
				py: { xs: 10, md: 15 },
			}}
		>
			<Stack
				spacing={3}
				sx={{
					textAlign: 'center',
					mb: { xs: 5, md: 10 },
				}}
			>
				<m.div variants={varFade().inUp}>
					<Typography component="div" variant="overline" sx={{ color: 'text.disabled' }}>
						Nos services
					</Typography>
				</m.div>

				<m.div variants={varFade().inUp}>
					<Typography variant="h2">Comment peut-on vous aider ?</Typography>
				</m.div>

				<m.div variants={varFade().inUp}>
					<Typography>
						Avec SHPV, vous disposez d'une gamme complète de services d'infrastructure et d'infogérance pour
						répondre à toutes les exigences de votre entreprise. Bénéficiez de nos solutions incluant le
						cloud dédié, le housing & Transit, l'hébergement mutualisé & dédié, et bien plus encore. Notre
						passion et l'expertise de nos équipes vous permettent de vous concentrer sur votre cœur de
						métier, nous prenons en charge votre gestion technique.
					</Typography>
				</m.div>
			</Stack>

			<Box
				gap={{ xs: 3, lg: 5 }}
				display="grid"
				alignItems={'stretch'}
				gridTemplateColumns={{
					xs: 'repeat(1, 1fr)',
					md: 'repeat(3, 1fr)',
				}}
			>
				{CARDS.map((card) => (
					<m.div variants={varFade().inUp} key={card.title}>
						<Card
							component={RouterLink}
							href={card.href}
							sx={{
								textAlign: 'center',
								boxShadow: { md: 'card' },
								bgcolor: 'background.default',
								height: '100%',
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'justify-between',
								alignItems: 'center',
								gap: (theme) => theme.spacing(2),
								padding: (theme) => theme.spacing(5, 3),
								cursor: 'pointer',
								transition: (theme) => theme.transitions.create('all', { duration: 300 }),
								'&:hover': {
									transform: () => ({
										md: 'translateY(-4px)',
									}),
									boxShadow: (theme) => ({
										md: `-40px 40px 80px ${
											theme.palette.mode === 'light'
												? alpha(theme.palette.grey[500], 0.16)
												: alpha(theme.palette.common.black, 0.4)
										}`,
									}),
								},
							}}
						>
							<Box display={'flex'} flexDirection={'column'} gap={2} flex={1}>
								<Box
									component="img"
									src={card.icon}
									alt={card.title}
									sx={{ mx: 'auto', width: 78, height: 78, mb: (theme) => theme.spacing(4) }}
								/>

								<Typography variant="h5">{card.title}</Typography>

								<Typography sx={{ color: 'text.secondary' }}>{card.description}</Typography>
							</Box>

							<Button
								aria-label={`En savoir plus sur ${card.title}`}
								component={RouterLink}
								href={card.href}
								color="inherit"
								size="small"
								variant="contained"
							>
								En savoir plus
							</Button>
						</Card>
					</m.div>
				))}
			</Box>
		</Container>
	)
}
