/*
| Developed by Dirupt
| Filename : HomeHero.tsx
| Author : Philippe DESPLATS (philippe@di-rupt.com)
*/

import React from 'react'
import { m, useScroll } from 'framer-motion'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Unstable_Grid2'
import Typography from '@mui/material/Typography'
import { alpha, styled } from '@mui/material/styles'
import { varFade, MotionContainer } from '@/components/animate'
import { bgGradient, textGradient } from '@/theme/css'
import { useResponsive } from '@/hooks/useResponsive'
import { HEADER } from '@/config/layout.config'
import { RouterLink } from '@/routes/components'
import Iconify from '@/components/iconify'
import { urlsConfig } from '@/config/urls.config'
import { pxToRem, responsiveFontSizes } from '@/theme/typography'
import { StyledAdvancedPolygon } from '@/components/divider/PolygonDivider'

/*
|--------------------------------------------------------------------------
| Styles
|--------------------------------------------------------------------------
*/
const StyledHero = styled('div')(({ theme }) => ({
	...bgGradient({
		color: alpha(theme.palette.background.default, theme.palette.mode === 'light' ? 0.9 : 0.94),
		imgUrl: '/assets/background/home/hero.jpg',
	}),
	width: '100%',
	height: '100vh',
	position: 'relative',
	[theme.breakpoints.up('md')]: {
		top: 0,
		left: 0,
		position: 'fixed',
		height: '90vh',
	},
}))

const StyledWrapper = styled('div')(({ theme }) => ({
	height: '100%',
	overflow: 'hidden',
	position: 'relative',
	[theme.breakpoints.up('md')]: {
		marginTop: HEADER.H_DESKTOP_OFFSET,
	},
}))

const StyledH1Gradient = styled(m.h1)(({ theme }) => ({
	...textGradient(
		`300deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 25%, ${theme.palette.primary.main} 50%, ${theme.palette.secondary.main} 75%, ${theme.palette.primary.main} 100%`,
	),
	padding: 0,
	marginTop: 8,
	lineHeight: 1,
	fontWeight: 900,
	marginBottom: 24,
	letterSpacing: 8,
	backgroundSize: '400%',
	fontSize: `${64 / 16}rem`,
	fontFamily: theme.typography.fontSecondaryFamily,
	textAlign: 'center',
	[theme.breakpoints.up('md')]: {
		fontSize: `${74 / 16}rem`,
		textAlign: 'left',
	},
}))

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export default function HomeHero() {
	const mdUp = useResponsive('up', 'md')
	const heroRef = React.useRef<HTMLDivElement | null>(null)
	const { scrollY } = useScroll()
	const [percent, setPercent] = React.useState(0)
	const opacity = 1 - percent / 100
	const hide = percent > 120

	// Get scroll percentage
	// ----------------------------------------------------------------------------
	const getScroll = React.useCallback(() => {
		let heroHeight = 0

		if (heroRef.current) {
			heroHeight = heroRef.current.offsetHeight
		}

		scrollY.on('change', (scrollHeight) => {
			const scrollPercent = (scrollHeight * 100) / heroHeight

			setPercent(Math.floor(scrollPercent))
		})
	}, [scrollY])

	React.useEffect(() => {
		getScroll()
	}, [getScroll])

	// Render
	// ----------------------------------------------------------------------------
	return (
		<Box sx={{ height: { md: '90vh' }, position: 'relative' }}>
			<StyledHero
				ref={heroRef}
				sx={{
					...(hide && {
						opacity: 0,
					}),
				}}
			>
				<StyledWrapper>
					<Container component={MotionContainer} sx={{ height: 1 }}>
						<Grid container columnSpacing={{ md: 10 }} sx={{ height: 1 }}>
							<Grid xs={12} md={12}>
								<Stack
									alignItems="center"
									justifyContent="center"
									sx={{
										height: 1,
										mx: 'auto',
										maxWidth: 600,
										opacity: opacity > 0 ? opacity : 0,
										mt: {
											xs: `${HEADER.H_MOBILE / 2}px`,
											md: `-${HEADER.H_DESKTOP + percent * 2.5}px`,
										},
									}}
								>
									<m.div variants={varFade().in}>
										<Typography
											variant="h3"
											component={'span'}
											sx={{
												display: 'block',
												width: '100%',
												textAlign: 'center',
												fontSize: pxToRem(24),
												...responsiveFontSizes({ sm: 26, md: 30, lg: 32 }),
											}}
										>
											Infrastructure & Infogérance
										</Typography>
									</m.div>

									<m.div variants={varFade().in}>
										<StyledH1Gradient
											animate={{ backgroundPosition: '200% center' }}
											transition={{
												repeatType: 'reverse',
												ease: 'linear',
												duration: 20,
												repeat: Infinity,
											}}
										>
											SHPV France
										</StyledH1Gradient>
									</m.div>

									<m.div variants={varFade().in}>
										<Typography variant="body2" sx={{ textAlign: 'center', mb: 1 }}>
											Votre partenaire de confiance en{' '}
											<strong>infrastructure et infogérance</strong> informatique en France.
										</Typography>
										<Typography variant="body2" sx={{ textAlign: 'center' }}>
											Découvrez comment notre expertise en infogérance et notre infrastructure{' '}
											<strong>haut de gamme</strong> peuvent catalyser la croissance de votre
											entreprise. Choisissez des services adaptés à vos besoins, conçus pour
											assurer la sécurité, la performance et la continuité de vos activités.
										</Typography>
									</m.div>

									<m.div variants={varFade().in}>
										<Stack
											spacing={1.5}
											direction={{ xs: 'column-reverse', sm: 'row' }}
											sx={{ mb: 5, my: 3 }}
										>
											<Stack alignItems="center" spacing={2}>
												<Button
													aria-label="Prendre rendez-vous"
													component={RouterLink}
													href={urlsConfig.contactUs}
													color="inherit"
													size="large"
													variant="contained"
													startIcon={<Iconify icon="eva:phone-call-fill" width={24} />}
												>
													Prendre rendez-vous
												</Button>
											</Stack>

											<Button
												aria-label={'Nos services'}
												color="inherit"
												size="large"
												variant="outlined"
												startIcon={<Iconify icon="eva:grid-fill" width={24} />}
												href={urlsConfig.services.index}
												sx={{ borderColor: 'text.primary' }}
											>
												Nos services
											</Button>
										</Stack>
									</m.div>
								</Stack>
							</Grid>
						</Grid>
					</Container>
				</StyledWrapper>
			</StyledHero>

			{mdUp ? (
				<React.Fragment>
					<StyledAdvancedPolygon anchor="left" opacity={0.48} />
					<StyledAdvancedPolygon anchor="left" opacity={0.48} sx={{ height: 48, zIndex: 10 }} />
					<StyledAdvancedPolygon anchor="left" sx={{ zIndex: 11, height: 24 }} />
					<StyledAdvancedPolygon anchor="right" opacity={0.48} />
					<StyledAdvancedPolygon anchor="right" opacity={0.48} sx={{ height: 48, zIndex: 10 }} />
					<StyledAdvancedPolygon anchor="right" sx={{ zIndex: 11, height: 24 }} />
				</React.Fragment>
			) : null}
		</Box>
	)
}
