/*
| Developed by Dirupt
| Filename : PolygonDivider.tsx
| Author : Philippe DESPLATS (philippe@di-rupt.com)
*/

import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import { bgBlur } from '@/theme/css'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
type StyledPolygonProps = {
	anchor?: 'top' | 'bottom'
}

type StyledAdvancedPolygonProps = {
	opacity?: number
	anchor?: 'left' | 'right'
	position?: 'top' | 'bottom'
	bgColor?: string
}

/*
|--------------------------------------------------------------------------
| Styles
|--------------------------------------------------------------------------
*/
export const StyledPolygon = styled(Box, {
	shouldForwardProp: (prop) => prop !== 'anchor',
})<StyledPolygonProps>(({ anchor = 'top', theme }) => ({
	left: 0,
	zIndex: 9,
	height: 80,
	width: '100%',
	position: 'absolute',
	clipPath: 'polygon(0% 0%, 100% 100%, 0% 100%)',
	backgroundColor: theme.palette.background.default,
	display: 'block',
	lineHeight: 0,
	...(anchor === 'top' && {
		top: -1,
		transform: 'scale(-1, -1)',
	}),
	...(anchor === 'bottom' && {
		bottom: -1,
		backgroundColor: theme.palette.grey[900],
	}),
}))

export const StyledAdvancedPolygon = styled('div', {
	shouldForwardProp: (prop) =>
		prop !== 'opacity' && prop !== 'anchor' && prop !== 'position' && prop !== 'bgColor' && prop !== 'sx',
})<StyledAdvancedPolygonProps>(({ opacity = 1, anchor = 'left', position = 'bottom', bgColor, theme }) => ({
	...bgBlur({
		opacity,
		color: bgColor ?? theme.palette.background.default,
	}),
	zIndex: 9,
	height: 80,
	width: '50%',
	position: 'absolute',
	...(position === 'top' && {
		clipPath: 'polygon(0% 0%, 100% 100%, 100% 0%)',
		top: -0.1,
	}),
	...(position === 'bottom' && {
		clipPath: 'polygon(0% 0%, 100% 100%, 0% 100%)',
		bottom: -0.1,
	}),
	...(anchor === 'left' && {
		left: 0,
		...(theme.direction === 'rtl' && {
			transform: 'scale(-1, 1)',
		}),
	}),
	...(anchor === 'right' && {
		right: 0,
		transform: 'scaleX(-1)',
		...(theme.direction === 'rtl' && {
			transform: 'scaleX(1)',
		}),
	}),
}))
